@import "vendor/_normalize";
@import "vendor/_roboto";
@import "vendor/fontawesome/solid";

$textColor: #333;
$headingColor: #000;
$accentColor: #db1131;
$linkColor: #d14242;
$lightGray: #e6e6e6;

@mixin dropdown-list {
    display: none;
    position: absolute;

    &.open {
        display: block;
    }

    ul {
        background-color: #fff;
        background-color: rgba(255,255,255,.98);
        border: 1px solid rgb(242, 242, 242);
        width: 270px;
        text-align: left;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            border-top: 1px solid rgb(242, 242, 242);

            &:first-child {
                border-top: none;
            }
        }

        a {
            display: block;
            color: $textColor;
            text-decoration: none;
            padding: 16px 20px;

            &::before {
                content: fa-content($fa-var-angle-right);
                @extend .fa;
                margin-right: .5em;
                color: #cacaca;
            }

            &:hover {
                color: $accentColor;

                &::before {
                    color: $accentColor;
                }
            }
        }
    }
}

* {
    box-sizing: border-box;
}

body {
    max-width: 80em;
    margin: 0 auto;
    font-family: 'Roboto',sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: $textColor;
    display: grid;
    grid-template-areas: "bar" "header" "main-navigation" "main" "footer";
    grid-template-rows: auto auto auto 1fr auto;
    min-height: 100vh;
}

a {
    color: $linkColor;

    &:hover {
        color: $textColor;
    }
}

.top-bar {
    grid-area: bar;
    height: 37.5px;
    vertical-align: baseline;
    display: grid;
    grid-template-areas: "links search";
    grid-template-columns: 1fr auto;
    color: #fff;
    line-height: 21px;
    padding: 0 30px;

    @media screen and (min-width: 80em) {
        padding: 0;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        height: 37.5px;
        background-color: rgb(46, 46, 46);
    }

    .top-bar-links {
        grid-area: links;
        list-style: none;
        margin: 6px 0;
        padding: 0;
        font-size: 13px;
        justify-self: end;

        @media screen and (min-width: 35em) {
            justify-self: stretch;
        }

        .detail-text {
            position: absolute;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
            white-space: nowrap; /* added line */
            @media screen and (min-width: 35em) {
                position: initial;
                height: initial;
                width: initial;
                overflow: initial;
                clip: initial;
                white-space: initial;
            }
        }

        li {
            display: inline-block;
            padding-left: 8px;

            @media screen and (min-width: 35em) {
                border-left: 1px solid #4c4c4c;
                padding-left: 15px;
                padding-right: 15px;
            }

            &:first-child {
                padding-left: 0;
                border-left: none;
            }

            a, a:hover {
                color: #fff;
                text-decoration: none;
            }
        }

        .top-bar-faq a {
            &::before {
                content: fa-content($fa-var-comments);
                @extend .fa;
                margin-right: 0.3em;
                font-size: 1.2em;
                font-family: "Font Awesome 5 Free";
            }
        }

        .top-bar-hotline {
            &::before {
                @extend .fa;
                margin-right: 0.3em;
                font-size: 1.2em;
                font-family: "Font Awesome 5 Free";
            }

            a {
                transition: color 0.2s ease-in-out;
                color: #747474;
                font-weight: bold;

                &::before {
                    content: fa-content($fa-var-phone);
                    @extend .fa;
                    margin-right: 0.3em;
                    font-size: 1.2em;
                    font-family: "Font Awesome 5 Free";
                    color: #fff;
                }
            }

            @media screen and (min-width: 35em) {
                &::before {
                    content: fa-content($fa-var-phone);
                }

                a::before {
                    content: none;
                }
            }
        }
    }

    .search-container {
        grid-area: search;
        display: none; // Wird sichtbar gemacht, wenn das entsprechende JS erfolgreich geladen wurde.
    }

    .search-field {
        background-color: #4a4a4a;
        color: #fff;
        margin-top: 6px;
        vertical-align: baseline;

        &::before {
            content: fa-content($fa-var-search);
            @extend .fa;
            position: absolute;
            margin-left: 8px;
        }
    }

    .search-results {
        @include dropdown-list();
        margin-top: 6px;
        max-height: 400px;
        overflow-y: auto;
    }

    .not-found {
        padding: 16px 20px;
        text-align: center;
    }

    input[type=search] {
        border: none;
        background: none;
        color: #fff;
        line-height: 21px;
        padding: 0 8px 0 2em;

        &::-webkit-input-placeholder {
            color: #9d9d9d;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}

header {
    text-align: center;
    grid-area: header;

    h1 {
        font-size: 32px;
        font-weight: 300;

        @media screen and (min-width: 20em) {
            font-size: calc(32px + (50 - 32) * (100vw - 320px) / (448 - 320));
        }

        @media screen and (min-width: 28em) {
            font-size: 50px;
        }

        a {
            color: $textColor;
            text-decoration: none;
        }
    }
}

footer {
    grid-area: footer;
    height: 123px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 1fr 20px;

    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        height: 123px;
        background-color: #262626;
        background-image: linear-gradient(0, #4a4a4a 0, #4a4a4a 20px, #262626 20px);
    }

    ul {
        list-style: none;
        margin: 0 auto;
    }

    li {
        display: inline-block;
        border-right: 1px solid #3b3b3b;
        margin-right: 15px;
        padding-right: 15px;

        &:last-child {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
        }
    }

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            color: #9d9d9d;
        }
    }
}

fieldset {
    margin: 0 0 15px 0;
    padding: 0;
    border: none;
}

.field-validation-error {
    display: block;
    margin-top: 5px;

    &::before {
        content: fa-content($fa-var-chevron-right);
        @extend .fa;
        margin-right: 5px;
        color: $accentColor;
    }
}

.field-required label::after {
    content: " *";
    color: $accentColor;
    font-size: 20px;
    vertical-align: bottom;
}

.validation-summary {
    ul {
        list-style-position: inside;
        padding-left: 0;
    }

    li {
        &::marker {
            content: fa-content($fa-var-chevron-right);
            @extend .fa;
            margin-right: 5px;
            color: $accentColor;
        }
    }
}

label {
    max-width: 100%;
    vertical-align: baseline;
    line-height: 14px;
    color: #313131;
    font-weight: 600;
    display: block;
    margin-bottom: 7px;
    padding-top: 5px;
    font-size: 14px;
}

input[type=text], input[type=email] {
    width: 100%;
    border: 1px #e6e6e6 solid;
    font-size: 13px;
    line-height: 43px;
    color: #313131;
    height: 45px;
    padding: 0 20px;
    transition: all 0.2s ease-out;
    outline: none;

    &:focus {
        background: #f7f7f7;
    }
}

input[type=checkbox] + label {
    font-weight: inherit;
    font-size: inherit;
    display: inline-block;
    margin-bottom: 4px;
    padding: 0 0 0 4px;
}

select {
    width: 100%;
    border: 1px #e6e6e6 solid;
    font-size: 13px;
    line-height: 43px;
    color: #313131;
    height: 45px;
    padding: 0 20px;
}

button {
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #000;
    padding: 10px 30px;
    background: rgba(255,255,255,.8);
    color: #000;
    border-radius: 20px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .06em;
    transition: all 0.2s ease-out;

    &:hover {
        background: rgba(255,255,255,.9);
        color: #db1131 !important;
        border-color: #db1131;
    }
}

.button-primary {
    color: #fff;
    background: $accentColor;
    border: 1px solid $accentColor;

    &:hover {
        background: #ac0d26;
        color: #fff !important;
        border: 1px solid #ac0d26;
    }
}

.header-hebrech {
    color: $accentColor;
}

.header-hebrech b {
    font-weight: 600;
}

#main-nav {
    grid-area: main-navigation;
    border-top: 2px solid #ebebeb;
    border-bottom: 2px solid #ebebeb;
    text-align: center;
    margin-bottom: 50px;

    h2 {
        font-size: 1em;
        letter-spacing: 0.84px;
        margin: 6px 0;

        a {
            display: inline-block;
            color: #3d3d3d;
            text-decoration: none;
            padding: 10px;

            &::after {
                content: fa-content($fa-var-chevron-down);
                @extend .fa;
                margin-left: .5em;
            }
        }
    }

    ul {
        list-style: none;
        display: inline-block;
        margin: 0;
        padding: 0;
    }

    & > ul > li {
        display: inline-block;
        padding: 0 5px;

        div {
            @include dropdown-list();
            margin: 0 -60px;
            padding-top: 7px;
        }

        &:hover h2 a {
            background-color: rgba(157, 157, 157, 0.25);
        }

        &:hover div {
            display: block;
        }
    }
}

#sub-nav {
    float: left;
    padding: 15px 20px 25px 20px;
    width: 200px;
    grid-area: sub-navigation;

    a {
        color: $textColor;
        text-decoration: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-top: 1px solid $lightGray;
            padding: 15px 0;
            font-weight: 600;
        }
    }

    h2 {
        margin: 0 0 -1px 0;
        padding: 15px 0;
    }
}

main {
    margin-left: 220px;
    margin-bottom: 40px;

    @supports(display: grid) {
        margin-left: 0;
        grid-area: main;
    }

    padding: 0 30px 30px 30px;

    @media screen and (min-width: 80em) {
        padding: 0 0 30px 0;
    }

    p {
        margin: 0 0 15px 0;
        line-height: 20px;
    }

    li {
        line-height: 20px;
    }

    .content-section {
        padding-bottom: 20px;
    }

    .content-section > :last-child {
        margin: 0;
    }
}

#SelectAll {
    margin-right: 15px;
    margin-bottom: 10px;
}

#UnsubscribeNewsletter {
    margin-right: 5px;
    margin-bottom: 10px;
}

.name-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px;
}
