@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../webfonts/roboto-v20-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'),
            url('../webfonts/roboto-v20-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../webfonts/roboto-v20-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
            url('../webfonts/roboto-v20-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
            url('../webfonts/roboto-v20-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../webfonts/roboto-v20-latin_latin-ext-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/roboto-v20-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
            url('../webfonts/roboto-v20-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../webfonts/roboto-v20-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('../webfonts/roboto-v20-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
            url('../webfonts/roboto-v20-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../webfonts/roboto-v20-latin_latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../webfonts/roboto-v20-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'),
            url('../webfonts/roboto-v20-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../webfonts/roboto-v20-latin_latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
            url('../webfonts/roboto-v20-latin_latin-ext-500.woff') format('woff'), /* Modern Browsers */
            url('../webfonts/roboto-v20-latin_latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../webfonts/roboto-v20-latin_latin-ext-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/roboto-v20-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
            url('../webfonts/roboto-v20-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../webfonts/roboto-v20-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('../webfonts/roboto-v20-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
            url('../webfonts/roboto-v20-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../webfonts/roboto-v20-latin_latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
}  